<template>
  <div class="docs-home">
    <common-header class="docs-head"></common-header>
    <div style="height: 100px"></div>
    <div class="doc-list">
      <div class="doc-card" v-for="(doc, index) in docs" :key="index">
        <div class="doc-icon" :style="{ 'background-image': 'url(' + doc.icon + ')' }"></div>
        <div class="doc-content">
          <h3>
            <em>{{ doc.title }}</em>
          </h3>
          <div class="doc-desc">{{ doc.desc }}</div>
          <a :href="doc.path" target="_blank"><em>查 看</em></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header'

export default {
  name: 'DocsHome',
  components: {
    'common-header': Header
  },
  data() {
    return {
      docs: [
        {
          icon: require('@/assets/docs/java.webp'),
          title: 'java 开发文档',
          desc: '让开发者可以方便地查阅 Java 相关的应用程序接口',
          path: 'http://docs.fenghuangji.com/java/11/api/index.html'
        },
        {
          icon: require('@/assets/docs/mysql.webp'),
          title: 'mysql 官方文档',
          desc: '让开发者可以快速地查阅 Mysql 相关的官方文档',
          path: 'https://dev.mysql.com/doc/refman/8.0/en/'
        },
        {
          icon: require('@/assets/docs/opencv.webp'),
          title: 'opencv 开发文档',
          desc: '让开发者可以快速地查阅 Opencv 相关的文档',
          path: 'http://docs.fenghuangji.com/opencv/455/index.html'
        }
      ]
    }
  },
  methods: {},
  mounted() {}
}
</script>

<style scoped>
.docs-home {
  width: 100%;
}

.docs-head {
  position: fixed;
  z-index: 999;
  box-shadow: 0 1px 40px -8px rgb(0 0 0 / 50%);
}

.doc-list {
  width: 50%;
  min-width: 800px;
  margin: 0 auto;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, auto);
  justify-content: space-evenly;
}

.doc-card {
  height: 150px;
  display: grid;
  grid-template-columns: 40% 60%;
  border: 1px solid #ccc;
  background-color: white;
  transition: box-shadow 0.3s ease-in-out;
}

.doc-card:hover {
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%);
}

.doc-icon {
  background-size: 90% 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.doc-content {
  height: 80%;
  position: relative;
  padding: 10% 10px;
  text-align: left;
}

.doc-content h3 {
  color: #333;
}

.doc-desc {
  margin-top: 10px;
  height: 50%;
  color: #8a8f97;
  font-size: 14px;
  text-align: left;
}

.doc-content a {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #333;
  font-size: 14px;
}

.doc-content a:hover {
  color: #fe9600;
}
</style>
